import React, { useState } from 'react';
import { styled } from '@mui/system';
import RemoveIcon from '../../assets/bin.svg';
import { Typography } from '@mui/material';
import StyledButton from '../Button/Button';
import { removeJournal } from 'src/redux/services/journals';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
// import { removeUser } from '../../redux/services/users';

const Container = styled('div')({
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  marginBottom: '16px',
});
function RemoveJournal({
  handleClose,
  setUsers,
  journals,
  journalId,
  fileTitle,
  setOpenSnackbar,
  setMessageObject,
  setNewFileComponent,
  removeFromJournalsList,
  removePage,
  deletePage,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const customNavigate = useCustomNavigate();
  const location = useLocation();
  const deleteJournal = () => {
    setLoading(true);
    removeJournal(journalId)
      .then((res) => {
        setLoading(false);
        console.log('DEL JOURNAL', res);
        handleClose();
        setMessageObject({
          text: res?.message,
          severity: 'success',
        });
        setOpenSnackbar(true);
        setTimeout(() => {
          customNavigate('/files', { state: { page: location.state } });
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        setMessageObject({
          text: err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.data,
          severity: 'error',
        });
        setOpenSnackbar(true);
      });
  };
  return (
    <>
      {removePage ? (
        <Container>
          <img src={RemoveIcon} alt='remove icon' width={200} />
          <Typography
            variant='subtitle1'
            align='left'
            sx={{
              fontSize: '14px',
              mb: 2,
              fontFamily: 'Open Sans',
              textAlign: 'center',
              fontWeight: '500',
              lineHeight: '24px',
              padding: '0px 10px',
            }}
          >
            {t(`Are you really want to remove`)} <strong>page</strong>?{' '}
            {t(
              `If you remove this page, it will permanently remove from file.`
            )}
          </Typography>

          <StyledButton
            onClick={deletePage}
            btnvariant='true'
            loading={loading}
          >
            {t(`Remove`)}
          </StyledButton>
        </Container>
      ) : (
        <Container>
          <img src={RemoveIcon} alt='remove icon' width={200} />
          <Typography
            variant='subtitle1'
            align='left'
            sx={{
              fontSize: '14px',
              mb: 2,
              fontFamily: 'Open Sans',
              textAlign: 'center',
              fontWeight: '500',
              lineHeight: '24px',
              padding: '0px 10px',
            }}
          >
            {t(`Are you really want to remove`)} <strong>{fileTitle}</strong>?{' '}
            {t(
              `If you remove this file, it will permanently remove from file’s list.`
            )}
          </Typography>

          <StyledButton
            onClick={deleteJournal}
            btnvariant='true'
            loading={loading}
          >
            Remove
          </StyledButton>
        </Container>
      )}
    </>
  );
}

export default RemoveJournal;
