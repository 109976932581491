import React from 'react';
import { styled } from '@mui/system';
import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-2.svg';
import CheckPermissions from '../CheckPermissions/CheckPermissions';
import apiConfig from '../../config/api.service';

const Container = styled('div')({
  display: 'flex',
  width: '184px',
  height: '184px',
  margin: '8px',
  padding: '8px',
  background: `#F6EEE0`,
});
const Card = styled('div')((props) => {
  return {
    display: 'flex',
    backgroundImage: `url("${apiConfig.imageUrlPrefix}${props.image}")`,
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'white',
  };
});
const Banner = styled('div')({
  position: 'absolute',
  bottom: '16px',
  background: '#BB8D0BE5',
  color: 'white',
  width: '100%',
  padding: '5px 0px',
  textAlign: 'center',
  fontSize: '14px',
  textTransform: 'uppercase',
  lineHeight: '19px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const Actions = styled('div')({
  position: 'absolute',
  top: '2px',
  display: 'flex',
  right: '2px',
});
const Icon = styled('div')({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: '#F6EEE0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: '2px',
});
function CategoryCard({ title, image, handleClickOpen, categoryData }) {
  return (
    <Container>
      <Card image={image}>
        <Actions>
          <CheckPermissions requiredPermission={['634fafba9b08601a8fff9b41']}>
            <Icon>
              <DeleteIcon
                onClick={() => handleClickOpen(categoryData, 'del')}
              />
            </Icon>
          </CheckPermissions>
          <CheckPermissions requiredPermission={['63da2f65e15d1c8c3f4f276f']}>
            <Icon>
              <EditIcon onClick={() => handleClickOpen(categoryData, 'edit')} />
            </Icon>
          </CheckPermissions>
        </Actions>
        <Banner>{title}</Banner>
      </Card>
    </Container>
  );
}

export default CategoryCard;
