import React, { useEffect, useState } from 'react';
// import AppWrapper from '../../../components/AppWrapper/AppWrapper';
// import TopBar from '../../../components/TopBar/TopBar'
import AppWrapper from 'src/components/AppWrapper/AppWrapper';
import TopBar from 'src/components/TopBar/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPage,
  getPages,
  getPresignedURL,
} from 'src/redux/services/category';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import StyledButton from 'src/components/Button/Button';
import JournalsTopRightBar from 'src/components/TopRightBar/Journals';
import { Button } from '@mui/material';
import DetailsModal from 'src/components/Modal/DetailsModal';
import PageViewer from 'src/components/PageViewer/PageViewer';
import CloseIcon from 'src/assets/close-icon-w.svg';
import PageUpdateForm from 'src/components/PageUpdateForm/PageUpdateForm';
import Drageable from 'src/components/Draggeable/Drageable';
import { createProgressBar } from 'src/helpers/fileprogress';
import DragDrop from 'src/components/DragDrop/DragDrop';
import apiConfig from '../../../config/api.service';
import {
  formatBytes,
  // getImageLinkForResolution,
  imgFileNameConvention,
  validateFileUpload,
} from 'src/helpers/utils';
import GalleryImport from 'src/assets/gallery-import.png';
import CloseIconB from 'src/assets/closeicon.svg';
import axios from 'axios';
import Modal from 'src/components/Modal/Modal';
import { getAllLanguagesTags, getAllTags } from 'src/redux/services/tags';
import { setJournalDetails, setPages } from 'src/redux/actions/general';
import TranslateButton from 'src/components/Button/TranslateBtn';
import { ReactComponent as TranslateIcon } from 'src/assets/translate-icon.svg';
import { translateData, translateDataSWToEN } from 'src/redux/services/general';
import Loader from 'src/components/Loader/Loader';
import DataNotFound from 'src/components/DataNotFound/DataNotFound';
import { getSpecificJournalTags, tagsToBeTranslated, translatedTags } from 'src/helpers/tags';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';
import { getSingleJournal } from 'src/redux/services/journals';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PageWrapper = styled('div')({
  width: '257px',
  cursor: 'pointer',
  img: {
    objectFit: 'cover',
    width: '99.5%',
    border: '1px solid #F2F2F2',
  },
  div: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    '.number': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '15%',
      fontWeight: '700',
      color: '#1A1A1A',
      border: '1px solid #F2F2F2',
    },
    div: {
      border: '1px solid #F2F2F2',
      width: '40%',
      paddingLeft: '4px',
      '#details': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      p: {
        dispaly: 'flex',
        alignSelf: 'center',
        fontSize: '12px',
        color: '#7B858F',
      },
    },
    Button: {
      width: '60%',
      borderRadius: '0',
      height: '36px',
      color: '#BB8D0B',
      border: '1px solid #F2F2F2',
      textTransform: 'capitalize',
    },
  },
});

const DetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '1296px',
});

const detailsModalStyling = {
  width: '1296px',
};

const PageDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '350px',
  overflowY: 'auto',
  height: '625px',
  gap: '25px',
  alignItems: 'center',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '24px',
  paddingLeft: '20px',
  paddingRight: '20px',
  '.title': {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '56px',
    borderBottom: '1px solid #F2F2F2',
    p: {
      fontSize: '16px',
      color: '#1A1A1A',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      div: {
        display: 'flex',
        alignItems: 'center',
        width: '36px',
        height: '36px',
        background: '#BB8D0B',
        justifyContent: 'space-around',
        cursor: 'pointer',
      },
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    label: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      span: {
        fontSize: '12px',
        color: '#292D32',
      },
    },
  },
});

const UploadModalBtnWrapper = styled('div')({
  display: 'flex',
  gap: '10px',
  margin: '15px',
  alignItems: 'center',
});

const uploadImgModalStyles = {
  minWindth: '432px',
  minHeight: '341px',
  display: 'flex',
};

const UploadedFilesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
  width: '400px',
  minHeight: '69px',
  border: '1px solid #F2F2F2',
  '#Selected-files-details': {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    img: {
      marginLeft: '8px',
      alignSelf: 'center',
    },
    div: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      gap: '6px',
      fontWeight: '500',
      '.files-count': {
        color: '#292D32',
      },
      '.files-size': {
        color: '#7B858F',
      },
    },
  },
  '#fileDel-icon': {
    alignSelf: 'center',
    marginRight: '10px',
    cursor: 'pointer',
  },
});

const UploadPage = () => {
  const [detailsModal, setDetailsModal] = useState(false);
  const [image, setImage] = useState();
  const [pageDetails, setPageDetails] = useState({ pageNum: '02' });
  const [uploadPage, setUploadPage] = useState(false);
  const [files, setFiles] = useState();
  const [fileSize, setFileSize] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [items, setItems] = useState();
  const [percentage, setPercentage] = useState(0);
  const [progressBar, setProgressBar] = useState(false);
  const [translate, setTranslate] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [translateBtnLoading, setTranslateBtnLoading] = useState(false);
  const [saveDetails, setSaveDetails] = useState(false);
  const [searchTags, setSearchTags] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState({});
  const [searchItems, setSearchItems] = useState(false);
  const [tagsOptions,setTagsOptons]=useState([]);
  const [desc,setDesc]=useState();



  const { pages, tags, allLanguagesTags, locale } = useSelector(
    (state) => state?.general
  );
  const { user } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  // const navigate=useNavigate();


  const detailsPages = (item) => {
    
    if(locale === "en"){
    setDesc(item?.description || item?.description_en);
    setPageDetails({
      ...pageDetails,
      pageNum:
        item?.pageNum?.toString().length > 1
          ? item?.pageNum
          : `0${item?.pageNum}`,
      id: item?._id,
      creator: user?._id,
      image: item?.image,
      journalId: id,
      description: item?.description || item?.description_en,
      description_sw: item?.description_sw, //temp
      tags: item?.tags,
      tags_sw: item?.tags_sw, //temp
      pageCount: pageCount,
    });
   }
  else{
    setDesc(item?.description || item?.description_sw);
    setPageDetails({
      ...pageDetails,
      pageNum:  item?.pageNum?.toString().length > 1
      ? item?.pageNum
      : `0${item?.pageNum}`,
      id: item?._id,
      creator: user?._id,
      image: item?.image,
      journalId: id,
      description: item?.description || item?.description_sw,
      description_en: item?.description_en, //temp
      tags: item?.tags,
      tags_sw: item?.tags_sw, //temp
      pageCount: pageCount,
    });
  }
    setImage(item?.image);
    setDetailsModal(true);
    setTagsOptons(item?.tags)
  };

  const pageWrapper = (item) => {
    return (
      <PageWrapper>
        <div>
          <LazyLoadImage
            id={item?.pageNum}
            srcSet={`
    ${apiConfig.imageUrlPrefix + item?.image} 1000w,
    ${apiConfig.imageUrlPrefix + item?.lowResImage} 200w
          `}
            effect="blur"
            delayMethod="throttle"
            delayTime={500}
            onDragStart={(e) => {
              e.preventDefault();
            }}
            alt="journal-pages"
            width="257px"
            height="181px"
          />
        </div>
        <div>
          <p id={`${item?._id}`} className="number">
            {item?.pageNum?.toString().length > 1
              ? item?.pageNum
              : `0${item?.pageNum}`}
          </p>
          <div>
            <p id="details">
              {locale === "en"
                ? item?.description_en || item?.description
                : item?.description_sw || item?.description}
            </p>
          </div>
          <Button id="details-btn" onClick={() => detailsPages(item)}>
            {t(`Add Detail`)}
          </Button>
        </div>
      </PageWrapper>
    );
  };

  const handleFileUpload = async (event) => {
    event?.preventDefault();
    let files;
    let index = 0;
    let fileSize = 0;
    let fileCount = 0;
    if (event?.type === 'change') {
      files = event?.target?.files;
    } else if (event?.type === 'drop') {
      files = event?.dataTransfer?.files;
    }

    // eslint-disable-next-line array-callback-return
    let allowedFiles = Object.entries(files)
      // eslint-disable-next-line array-callback-return
      ?.filter((file) => {
        if (validateFileUpload(['jpg', 'jpeg', 'png', 'gif'], file[1]?.name)) {
          fileCount++;
          return file;
        }
      })
      .reduce((accum, [, v]) => {
        accum[index++] = v;
        return accum;
      }, {});
    allowedFiles.length = fileCount;

    console.log(allowedFiles);

    //Total Size Of Files
    for (let i = 0; i < allowedFiles.length; i++) {
      fileSize += allowedFiles[i]?.size;
    }

    if (fileCount >= 1) {
      setFileSize(formatBytes(fileSize));
      setFiles(allowedFiles);
    } else {
      setOpenSnackbar(true);
      setMessageSnackbar({ severity: 'info', text: t('File Not Supported') });
    }
  };

  const uploadImages = async (files, journalId) => {
    let fileCount = 0;
    setDisableBtn(true);
    try {
      setUploadPage(false);
      for (let i = 0; i < files.length; i++) {
        // setPageCount(pageCount + (i+1));
        //getPresignedUrl
        const { presignedUrl, imgKey } = await getPresignedURL({
          contentType: files[i]?.type,
          key: `/journal/:${id}/page/:${imgFileNameConvention(files[i]?.name)}`,
        });

        //Upload Image On S3 Bucket
        // eslint-disable-next-line no-unused-vars
        axios({
          method: 'PUT',
          data: files[i],
          headers: {
            'Content-Type': files[i]?.type,
          },
          url: presignedUrl,
          // eslint-disable-next-line no-loop-func
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let precentage = Math.floor((loaded * 100) / total);
            console.log('file=>' + files[i]?.name + ' => ' + precentage);
            if (precentage === 100) {
              fileCount++;
              // setPageCount(pageCount + 1);
            }
            createProgressBar(
              files[i]?.name,
              `${formatBytes(total)}`,
              precentage,
              fileCount,
              files.length
            );
          },
          // eslint-disable-next-line no-loop-func
        }).then((res) => {
          console.log(i);
          //Add Page Call
          const data = {
            image: imgKey,
            journal: journalId,
            pageNum: i + 1 + (pageCount || 0),
          };
          addPage(data)
            .then((res) => {
              setTimeout(() => {
                setItems((prevState) => [...prevState, res]);
              }, 2000);
            })
            .catch((err) => {
              let currentFileprogress = document.getElementById(
                `${journalId}${files[i]?.name}1`
              );
              let progressContainer = document.getElementById(
                `${journalId}${files[i]?.name}`
              );
              let fileSize = document.getElementById(
                `${journalId}${files[i]?.name}2`
              );
              progressContainer.children[1].children[0].children[0].style.color =
                'red';
              fileSize.children[0].style.color = 'red';
              currentFileprogress.style.background = 'red';
              currentFileprogress.style.border = '1px solid red';
            });
        });
      }
    } catch (err) {
      console.log(err);
      //Upload Fail
      // let fileUpload = document.getElementById("file-count");
      // fileUpload.innerHTML = "Failed";
      setPageCount(pageCount);
    } finally {
      setDisableBtn(false);
      setFiles('');
      setPageCount(pageCount + files?.length);
    }
  };

  const handleChange = (e) => {
    console.log(e?.target?.value.length);
    setSearchTags(e?.target?.value);
  };

  const handleClick = () => {
    if (searchTags?.length) {
      let searchItems = pages?.pages?.filter((item) => {
        console.log(item?.tags);
        return getSpecificJournalTags(item?.tags, locale)?.includes(
          searchTags.toLowerCase()
        );
      });
      console.log(searchItems);
      if (searchItems?.length) {
        setItems(searchItems);
        setSearchItems(true);
      } else {
        setOpenSnackbar(true);
        setMessageSnackbar({
          severity: 'info',
          text: `No results for ${searchTags}`,
        });
      }
    }
  };

  const handleSearch = () => {
    console.log('2' + searchTags);
  };
  const handleTranslation = async () => {

    try {
      let data;
      console.log(desc)
      console.log(pageDetails?.description)
      if(desc !== pageDetails?.description){
      setDesc(pageDetails?.description);
      data={
        description: pageDetails?.description,
        // eslint-disable-next-line array-callback-return
        tags: tagsToBeTranslated(tagsOptions, locale),
      }
      }
      else{
        data={
          tags: tagsToBeTranslated(tagsOptions, locale),
        }
      }
      setTranslateBtnLoading(true);
      //Language Switch Check
      let res;
      if (locale === "en") {
        res = await translateData(data, locale);
      } else {
        res = await translateDataSWToEN(data, locale);
      }
      //getExistingDescTranslation
      if(desc !== pageDetails?.description){
      setPageDetails({
        ...pageDetails,
        description_sw: res?.description,
      });
    }
      setTagsOptons(translatedTags(tagsOptions, res?.tags, locale));
    } catch (err) {
      console.log(err);
    } finally {
      setTranslateBtnLoading(false);
    }
    setTranslate(true);
    
  //   try {
  //     console.log(tagsEN);
  //     const data = {
  //       description: pageDetails?.description,
  //       // eslint-disable-next-line array-callback-return
  //       tags: checkExistingTags(tagsEN)
  //     };
  //     setTranslateBtnLoading(true);
      
  //     let res;
  //     //Language Switch Check
  //     if(locale === "en"){
  //     res = await translateData(data);
  //     }
  //     else{
  //     res = await translateDataSWToEN(data);
  //     }
  //     console.log(res);

  //     //getExistingDescTranslation
  //     setPageDetails({
  //       ...pageDetails,
  //       description_sw: res?.description,
  //       tags_sw: res?.tags || [],
  //     });
    
  //  setTagsSW(getExistingSWTags(allLanguagesTags, res?.tags, tagsEN, locale));
  //   } catch (err) {
  //     // console.log(err);
  //   } finally {
  //     setTranslateBtnLoading(false);
  //   }
  //   setTranslate(true);
  };

  const getJournalPages = async () => {
    try {
      await getPages(id, dispatch, locale);
      const res = await getSingleJournal(id, locale);
      dispatch(setJournalDetails(res?.data));
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getJournalPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    dispatch(() => getAllTags(dispatch,locale))()
      .then((res) => {})
      .catch((err) => {});
    getAllLanguagesTags(dispatch, locale);
    // eslint-disable-next-line
  }, [locale]);

  useEffect(() => {
    if (pages) {
      setItems(pages?.pages);
      setPageCount(pages?.totalCount);
    }
  }, [pages]);

  //Remove Pages On Unmount
  useEffect(() => {
    return () => {
      dispatch(setPages([]));
      setItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(()=>{
  //   if(pageDetails?.tags_sw){
  //    setTagsSW(pageDetails?.tags_sw)
  //   }
  //   if(pageDetails?.tags){
  //     setTagsEN(pageDetails?.tags)
  //   }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //  },[translate])

  return (
    <AppWrapper>
      {detailsModal ? (
        <DetailsModal
          open={detailsModal}
          handleOpen={() => setDetailsModal(true)}
          handleClose={() => {
            setDetailsModal(false);
            setTranslate(false);
          }}
          style={detailsModalStyling}
          children={
            <DetailsWrapper>
              <PageViewer
                img={apiConfig.imageUrlPrefix + image}
                percentage={percentage}
                progressBar={progressBar}
              />
              <PageDetails>
                <div className='title'>
                  <p>{t(`Add Page Detail`)}</p>
                  <div>
                    <TranslateButton
                      loading={translateBtnLoading}
                      onClick={handleTranslation}
                    >
                      <TranslateIcon style={{ marginRight: '4px' }} />
                      {t(`Translate`)}
                    </TranslateButton>

                    <div
                      onClick={() => {
                        setDetailsModal(false);
                        setTranslate(false);
                      }}
                    >
                      <img src={CloseIcon} alt='close-icon' />
                    </div>
                  </div>
                </div>
                <PageUpdateForm
                  items={items}
                  setItems={setItems}
                  setImage={setImage}
                  image={image}
                  setDetailsModal={setDetailsModal}
                  allTags={tags?.data}
                  allLanguageTags={allLanguagesTags}
                  setPercentage={setPercentage}
                  setProgressBar={setProgressBar}
                  progressBar={progressBar}
                  pageDetails={pageDetails}
                  setPageDetails={setPageDetails}
                  translate={translate}
                  setTranslate={setTranslate}
                  saveDetails={saveDetails}
                  setSaveDetails={setSaveDetails}
                  searchItems={searchItems}
                  pages={pages}
                  language={locale}
                  setOpenSnackbar={setOpenSnackbar}
                  setMessageSnackbar={setMessageSnackbar}
                  tagsOptions={tagsOptions}
                  setTagsOptons={setTagsOptons}
                />
              </PageDetails>
            </DetailsWrapper>
          }
        />
      ) : null}

      {uploadPage ? (
        <Modal
          title={t('Upload and attach file')}
          open={uploadPage}
          style={
            files
              ? { ...uploadImgModalStyles, minHeight: '209px' }
              : { ...uploadImgModalStyles }
          }
          handleClose={() => setUploadPage(false)}
          children={
            <>
              {!files ? (
                <DragDrop handleFileUpload={handleFileUpload} />
              ) : (
                <UploadedFilesWrapper>
                  <div id='Selected-files-details'>
                    <img
                      alt=''
                      src={GalleryImport}
                      width='32px'
                      height='32px'
                    />
                    <div>
                      <p className='files-count'>
                        {files?.length} Pages selected
                      </p>
                      <p className='files-size'>{fileSize}</p>
                    </div>
                  </div>
                  <div id='fileDel-icon' onClick={() => setFiles(null)}>
                    <img alt='' src={CloseIconB} />
                  </div>
                </UploadedFilesWrapper>
              )}
              <UploadModalBtnWrapper>
                <StyledButton
                  secondary='true'
                  onClick={() => setUploadPage(false)}
                >
                  {t(`Cancel`)}
                </StyledButton>
                <StyledButton
                  secondary
                  disable={disableBtn || !files}
                  onClick={() => uploadImages(files, id)}
                >
                  {t(`Upload`)}
                </StyledButton>
              </UploadModalBtnWrapper>
            </>
          }
        />
      ) : null}

      {openSnackbar ? (
        <StyledSnackbar
          handleClose={() => setOpenSnackbar(false)}
          open={openSnackbar}
          messageObject={messageSnackbar}
        />
      ) : null}

      <TopBar
        custom
        backLink
        onClick={() => window?.history?.back()}
        title={t('Upload Page')}
        rightSide={
          <JournalsTopRightBar
            display={{
              search: true,
              btn: true,
              dropdown: false,
              iconBtn: false,
            }}
            text={t('Upload Page')}
            handleChange={handleChange}
            handleClick={handleClick}
            handleSearch={handleSearch}
            handleClickOpen={() => setUploadPage(true)}
          />
        }
      />
      {items ? (
        items?.length >= 1 ? (
          <Drageable
            pages={items}
            allPages={pages}
            searchItems={searchItems}
            Component={pageWrapper}
          />
        ) : (
          <DataNotFound data='Page' />
        )
      ) : (
        <Loader />
      )}
    </AppWrapper>
  );
};

export default UploadPage;
