import { Typography, Grid, Box } from '@mui/material';
import React, { useState } from 'react';
import StyledButton from '../Button/Button';
import StyledInput from '../Input/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../../assets/unlock.svg';
import { useFormik } from 'formik';
import { userRegistrationSchema } from './validation';
import { styled } from '@mui/system';
import { userRegister } from '../../redux/services/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../redux/actions/auth';
import { useTranslation } from 'react-i18next';

const initialVal = {
  password: 'password',
  confirmPassword: 'password',
};
const initialValues = {
  fullname: '',
  password: '',
  confirmPassword: '',
};
const ErrorMessage = styled('div')({
  width: '100%',
  textAlign: 'left',
  color: '#B1484E',
  fontSize: '12px',
  lineHeight: '24px',
  minHeight: '24px',
  // marginTop: '-16px',
});

const RegisterFormFields = [
  {
    id: '1',
    fieldName: 'Enter Name',
    placeholder: 'Full Name',
    type: 'text',
    adornment: false,
    key: 'fullname',
  },
  {
    id: '2',
    fieldName: 'Password',
    placeholder: 'Password',
    adornment: true,
    key: 'password',
  },
  {
    id: '3',
    fieldName: 'Confirm Password',
    placeholder: 'Password',
    adornment: true,
    key: 'confirmPassword',
  },
];

const setFormData = (values) => {
  values['name'] = values['fullname'];
  delete values.confirmPassword;
  delete values['fullname'];

  let inviteRes = JSON.parse(localStorage.getItem('InviteData'));
  let data = {
    ...inviteRes,
    ...values,
  };
  Object.keys(data).map(
    (val) =>
      (data[val] = typeof data[val] == 'string' ? data[val].trim() : data[val])
  );
  return data;
};
function RegisterForm({ title, subtitle }) {
  const [toggleInputType, setToggleInputType] = useState(initialVal);
  const [loading, setLoading] = useState(false);
  // const [formData, setFormData] = useState(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {t}=useTranslation();
  const handlePasswordToggle = (type) => {
    const temp = { ...toggleInputType };
    temp[type] = temp[type] === 'text' ? 'password' : 'text';
    setToggleInputType(temp);
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userRegistrationSchema,
    onSubmit: (values) => {
      setLoading(true);
      let data = setFormData(values);

      console.log(data);
      dispatch(userRegister)(data)
        .then((res) => {
          setLoading(false);
          let receivedTime = new Date();
          dispatch(setAuthenticated('authenticated'));
          Navigate('/files');
          localStorage.setItem('token', res?.data?.token);
          localStorage.setItem('refreshToken', res?.data?.user?.refreshToken);
          localStorage.setItem('time', JSON.stringify(receivedTime));
        })
        .catch((err) => {
          dispatch(setAuthenticated('unauthenticated'));
          setLoading(false);
        });
    },
  });

  const setInputAdornment = (type) => {
    return (
      <InputAdornment position='end'>
        {toggleInputType[type] === 'password' ? (
          <LockIcon onClick={() => handlePasswordToggle(type)} />
        ) : (
          <UnlockIcon onClick={() => handlePasswordToggle(type)} />
        )}
      </InputAdornment>
    );
  };
  return (
    <Grid container item xs={12}>
      <Grid item sx={{ mb: 4 }} xs={12}>
        <Typography
          variant='h1'
          align='center'
          sx={{
            fontSize: '36px',
            mb: 2,
            letterSpacing: '1.5px',
            fontFamily: 'brandon-grotesque',
            fontWeight: '420',
          }}
        >
          {t(title)}
        </Typography>
        <Typography
          variant='subtitle1'
          align='center'
          sx={{ fontSize: '14px', fontFamily: 'Open Sans' }}
        >
          {t(subtitle)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit}
          noValidate={true}
        >
          <Box sx={{ mb: '36px' }}>
            {RegisterFormFields.map((ele) => {
              return (
                <Box key={ele.id} sx={{ mb: '8px' }}>
                  <Typography
                    variant='subtitle1'
                    align='left'
                    sx={{
                      fontSize: '14px',
                      mb: 1,
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      color: '#292D32',
                    }}
                  >
                    {t(ele.fieldName)}
                  </Typography>
                  <StyledInput
                    placeholder={t(ele.placeholder)}
                    type={
                      ele.adornment
                        ? ele.key === 'password'
                          ? toggleInputType.password
                          : toggleInputType.confirmPassword
                        : ele.type
                    }
                    name={ele.key}
                    style={{ marginBottom: '0px' }}
                    value={values.name}
                    onChange={handleChange}
                    errorclass={
                      errors[ele.key] && touched[ele.key]
                        ? 'errorMessage'
                        : null
                    }
                    // onChange={(e) => handleFormInput(ele.key, e.target.value)}
                    InputProps={{
                      endAdornment: ele.adornment
                        ? setInputAdornment(ele.key)
                        : null,
                    }}
                  />
                  <ErrorMessage>
                    {errors[ele.key] && touched[ele.key]
                      ? t(errors[ele.key])
                      : null}
                  </ErrorMessage>
                </Box>
              );
            })}
          </Box>
          <StyledButton
            style={{ fontWeight: '500' }}
            type='submit'
            loading={loading}
          >
            {t('REGISTER')}
          </StyledButton>
        </form>
      </Grid>
    </Grid>
  );
}

export default RegisterForm;
