import React from 'react';
import StyledAutoComplete from 'src/components/AutoComplete/StyledAutoComplete';
import { styled } from '@mui/system';
import CloseIcon from 'src/assets/close-g.svg';

const ListWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  div: {
    display: 'flex',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    height: '36px',
    width: '90%',
    border: '1px solid #D9DEE2',
    input: {
      outline: 'none',
      border: 'none',
      width: '90%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    span: {
      display: 'flex',
      justifyContent: 'space-around',
      borderRight: '1px solid #D9DEE2',
      width: '37px',
      textAlign: 'center',
      alignItems: 'center',
      color: '#BB8D0B',
    },
    button: {
      background: '#fff',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
    },
  },
});

const TagsFieldList = ({
  options,
  language,
  tagsOptions,
  setTagsOptons,
  setDetectTagsField,
  page,
}) => {
  // const [closeIcon,setCloseIcon]=useState(false);

  const checkSelectedTags = (value) => {
    if (language === 'en') {
      return tagsOptions.find((tag) => tag?.name_en.toLowerCase() === value.toLowerCase());
    } else {
      return tagsOptions.find((tag) => tag?.name_sw.toLowerCase() === value.toLowerCase());
    }
  };

  const handleInputChange = (inputValue, value) => {
    let selectedTags = checkSelectedTags(
      typeof value?.[0] === "object" ? value?.[0]?.name : value?.[0] || inputValue
    );
    if ((inputValue.length || typeof value === 'object') && !selectedTags) {
      if (value && typeof value[value?.length - 1] === 'object') {
        value[value.length - 1] = {
          ...value[value.length - 1],
          name_en: value[value.length - 1]?.name_en.toLowerCase(),
          name_sw: value[value.length - 1]?.name_sw.toLowerCase(),
        };
        //existing tags
        setTagsOptons((prevState) => [value[value.length - 1], ...prevState]);
      } else {
        //new tags
        let res;
        if (language === 'en') {
          res = { name: inputValue.toLowerCase(), name_en: inputValue.toLowerCase(), name_sw: '' };
        } else {
          res = { name: inputValue.toLowerCase(), name_sw: inputValue.toLowerCase(), name_en: '' };
        }
        setTagsOptons((prevState) => [res, ...prevState]);
      }
    }
  };
  // console.log(tagsOptions)

  const handleOptionChangeOriginal = (e, index) => {
    setDetectTagsField(true);
    if (language === 'en') {
      let data = tagsOptions?.map((tags, ind) => {
        if (index === ind) {
          tags.name = e.target.value?.toLowerCase();
          tags.name_en = e.target.value?.toLowerCase();
        }
        return tags;
      });
      setTagsOptons(data);
    } else {
      let data = tagsOptions?.map((tags, ind) => {
        if (index === ind) {
          tags.name = e.target.value?.toLowerCase();
          tags.name_sw = e.target.value?.toLowerCase();
        }
        return tags;
      });
      setTagsOptons(data);
    }
  };
  const handleOptionChangeTranslation = (e, index) => {
    // console.log(e.target.value);
    // console.log(index);
    setDetectTagsField(true);
    if (language === 'en') {
      let data = tagsOptions?.map((tags, ind) => {
        if (index === ind) {
          tags.name_sw = e.target.value?.toLowerCase();
        }
        return tags;
      });
      setTagsOptons(data);
    } else {
      let data = tagsOptions?.map((tags, ind) => {
        if (index === ind) {
          tags.name_en = e.target.value?.toLowerCase();
        }
        return tags;
      });
      setTagsOptons(data);
    }
  };

  const removeTag = (index) => {
    window.addEventListener('click', (event) => {
      if(event.target.id === "remove-tag"){
        let data = tagsOptions?.filter((tags, ind) => index !== ind);
        setTagsOptons(data);
      }
    });
  };

  return (
    <div
      style={
        tagsOptions.length > 3 ? { maxHeight: '200px', overflowY: 'auto' } : {}
      }
    >
      <StyledAutoComplete
        type='text'
        name=''
        options={options}
        style={{ marginBottom: '-16px' }}
        handleAddBtnClick={handleInputChange}
        handleInputChange={(e, value) =>
          handleInputChange(e.target.value, value)
        }
        page
      />
      {tagsOptions
        ? tagsOptions?.map((tag, index) => {
            return (
              <ListWrapper
                id='list'
                // onMouseOver={()=> setCloseIcon(true)} onMouseOut={()=>setCloseIcon(false)}
              >
                <div>
                  <span>{language === 'en' ? 'EN' : 'SW'}</span>
                  <input
                    type='text'
                    value={
                      language === 'en'
                        ? tag?.name_en || tag?.name
                        : tag?.name_sw
                    }
                    disabled={'_id' in tag || false}
                    onChange={(e) => handleOptionChangeOriginal(e, index)}
                  />
                </div>
                <div>
                  <span>{language === 'en' ? 'SW' : 'EN'}</span>
                  <input
                    type='text'
                    value={language === 'en' ? tag?.name_sw : tag?.name_en}
                    // disabled={"_id" in tag}
                    onChange={(e) => handleOptionChangeTranslation(e, index)}
                  />
                  <button onClick={() => removeTag(index)}>
                    <img
                      id="remove-tag"
                      src={CloseIcon}
                      width='12px'
                      height='12px'
                      alt='close-icon'
                    />
                  </button>
                </div>
              </ListWrapper>
            );
          })
        : null}
    </div>
  );
};

export default TagsFieldList;
