import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TopBar from '../../../components/TopBar/NewJournal';
import { styled } from '@mui/system';
import StyledButton from '../../../components/Button/Button';
import { Typography } from '@mui/material';
import AddJournal from '../../../components/AddJournalForm/AddJournalForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from 'src/components/Modal/Modal';
import DragDrop from 'src/components/DragDrop/DragDrop';
import { useDispatch } from 'react-redux';
import UploadJournalCoverForm from '../../../components/AddJournalForm/UploadJournalCover/UploadJournalCoverForm';
import { addNewFileSchema } from '../../../components/AddJournalForm/validation';
import { validateBeforeNavigate } from 'src/hooks/useCustomNavigate';
import {
  translateData,
  translateDataSWToEN,
} from '../../../redux/services/general';
import { useSelector } from 'react-redux';
import {
  getSingleJournal,
  updateJournal,
} from '../../../redux/services/journals';
import { getAllLanguagesTags, getAllTags } from '../../../redux/services/tags';
import { ReactComponent as CoverIcon } from '../../../assets/cover-icon.svg';
import StyledSnackbar from 'src/components/Snackbar/Snackbar';
import GalleryImport from 'src/assets/gallery-import.png';
import CloseIcon from 'src/assets/closeicon.svg';
import CheckPermissions from 'src/components/CheckPermissions/CheckPermissions';
import RemoveJournal from 'src/components/AddJournalForm/RemoveJournal';
import {
  changeDeteckTags,
  tagsToBeTranslated,
  translatedTags,
} from 'src/helpers/tags';
import { useTranslation } from 'react-i18next';
import {
  imgFileNameConvention,
  journalFieldsToBeTranslated,
  translatedJournalFormat,
  validateFileUpload,
} from 'src/helpers/utils';
import AppWrapper from 'src/components/AppWrapper/AppWrapper';
import { formatBytes } from 'src/helpers/utils';
import { createProgressBar } from 'src/helpers/fileprogress';
import axios from 'axios';
import {
  addPage,
  getPages,
  getPresignedURL,
} from 'src/redux/services/category';
import Loader from 'src/components/Loader/Loader';
import UnsavedChangesWarning from 'src/components/UnsavedChangesWarning/UnsavedChanges';
import { setJournalDetails } from 'src/redux/actions/general';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';

// Styled components
const StyledGrid = styled(Grid)({
  height: 'calc(100vh - 125px)',
});
const PagesCount = styled('span')({
  color: '#292D32',
  fontSize: '14px',
  fontWeight: '500',
});
const RightSideWrapper = styled('div')({
  margin: '24px 22px',
  border: '1px solid #F2F2F2',
});
const UploadModalBtnWrapper = styled('div')({
  display: 'flex',
  gap: '10px',
  margin: '15px',
  alignItems: 'center',
});
const buttonStyles = {
  // minWidth: '317px',
  maxWidth: '47%',
  margin: '8px',
  background: 'white !important',
  color: '#BB8D0B !important',
};
const uploadImgModalStyles = {
  minWindth: '432px',
  minHeight: '341px',
  display: 'flex',
};
const UploadedFilesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
  width: '400px',
  minHeight: '69px',
  border: '1px solid #F2F2F2',
  '#Selected-files-details': {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    img: {
      marginLeft: '8px',
      alignSelf: 'center',
    },
    div: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      gap: '6px',
      fontWeight: '500',
      '.files-count': {
        color: '#292D32',
      },
      '.files-size': {
        color: '#7B858F',
      },
    },
  },
  '#fileDel-icon': {
    alignSelf: 'center',
    marginRight: '10px',
    cursor: 'pointer',
  },
});
let initialVal;
let initialTagsVal;
let initialFile;

function EditJournalContainer() {
  // eslint-disable-next-line no-unused-vars
  const [journals, setJournals] = useState(null);
  const [fileData, setFileData] = useState();
  const [swFields, setSwFields] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [allowsSwFields, setAllowSwFields] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [pagesUploaded, setPagesUploaded] = useState(false);
  const [removeJournalComponent, setRemoveJournalComponent] = useState(false);
  const [fileCover, setFileCover] = useState();
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [translateBtnLoading, setTranslateBtnLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const [tagsSW, setTagsSW] = useState([]);
  const [tagsEN, setTagsEN] = useState([]);
  const [selectedTagsEN, setSelectedTagsEN] = useState([]);
  const [selectedTagsSW, setSelectedTagsSW] = useState([]);
  const [files, setFiles] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [fileSize, setFileSize] = useState();
  const [journalId, setJournalId] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  // const [cacheApiResponse, setCacheApiResponse] = useState();
  const [navigationLink, setNavigationLink] = useState(null);
  // const [count, setCount] = useState(0);
  const [toalFileCount, setTotalFileCount] = useState(0);
  const [singleJournalDetails, setSingleJournalDetails] = useState();
  // eslint-disable-next-line no-unused-vars
  const [uploadNewPagesCount, setUploadNewPagesCount] = useState(0);
  const [tagsOptions, setTagsOptons] = useState([]);
  const [detectChangeTags, setDetectChangeTags] = useState();
  const [detectTagsField, setDetectTagsField] = useState(false);

  const [journalFieldsEN, setJournalFieldsEN] = useState();
  const [saveChangesLoader, setSaveChangesLoader] = useState(false);
  // const allTags = useSelector((state) => state.general.tags?.data);
  const { allLanguagesTags } = useSelector((getState) => getState?.general);
  const { t } = useTranslation();
  const userId = useSelector((state) => state.auth.user._id);
  const { pages, locale } = useSelector((state) => state.general);
  let { id } = useParams();
  const location = useLocation();
  const customNavigate = useCustomNavigate();

  useEffect(() => {
    let res;
    if (fileData?.category) {
      res = categoriesData?.find((ele) => ele?._id === fileData?.category);
    }
    setFileData({
      ...fileData,
      category_sw: locale === 'en' ? res?.name_sw : res?.name_en,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData?.category]);

  useEffect(() => {
    setJournalFieldsEN('');
    setSwFields(false);
    getAllLanguagesTags(dispatch, locale);
    dispatch(getAllTags)()
      .then((res) => {})
      .catch((err) => {});
    // eslint-disable-next-line
  }, [locale]);

  useEffect(() => {
    console.log('Journal edit ID', id);
    setJournalId(id);
    console.log('STATE', location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (url) => {
    if (journalId) {
      navigate(url, { state: { page: location.state } });
    }
  };

  const handleSnackbar = () => {
    setOpenSnackbar(false);
  };

  const categoriesData = useSelector(
    (state) => state.allCategories.categories?.data
  );
  useEffect(() => {
    console.log('FILE COVER', fileCover, fileData?.image);

    validateBeforeNavigate((options) => {
      if (fileData) {
        setNavigationLink(options.url);
        console.log('Initial Value', initialVal);
        console.log('OPTION', options.url, initialFile, fileData?.image);
        // let temp = fileData;
        let temp = {
          ...fileData,
          image: fileCover !== undefined ? fileCover : fileData?.image,
        };
        console.log('TEMP', temp);
        delete temp.category_sw;
        if (JSON.stringify(initialVal) !== JSON.stringify(temp)) {
          setShowWarning(true);
          return false;
        }
      }
      if (tagsOptions) {
        setNavigationLink(options.url);
        if (
          !changeDeteckTags(tagsOptions, detectChangeTags) ||
          detectTagsField
        ) {
          setShowWarning(true);
          return false;
        }
      }
      return true;
    });
    return () => {
      validateBeforeNavigate(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData, tagsOptions, detectTagsField, fileCover]);

  useEffect(() => {
    if (journalId) {
      getSingleJournal(journalId, locale)
        .then(({ data }) => {
          console.log(data);
          setFileTitle(data?.title);
          console.log('data', data);
          data.fileTitle = data?.title;
          data.details = data?.subtitle;
          // let category = data?.category?.name;
          data.category = data?.category?._id;
          setTagsOptons(data?.tags);
          setDetectChangeTags(data?.tags);
          initialTagsVal = { ...data?.tags };
          initialVal = { ...data };
          console.log('initial ', initialVal);
          console.log('initial Tags Value ', initialTagsVal);
          setFileData((prevData) => {
            return { ...prevData, ...data };
          });

          // setCacheApiResponse(data);
          // console.log('new data', data);
          setSingleJournalDetails(data);
          dispatch(setJournalDetails(data));
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId, updateJournal, locale]);

  useEffect(() => {
    console.log('fileCover');
    if (swFields === true) fileData.showSW = true;
    addNewFileSchema
      .validate(fileData)
      .then(function (value) {
        setDisabledButton(false);
      })
      .catch(function (err) {
        setDisabledButton(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData, swFields]);

  // Upload Image
  const uploadImages = async (files, journalId) => {
    console.log('INSIDE upload', files.length);
    if (journalId) {
      setUploadNewPagesCount(files?.length);
      let fileCount = 0;
      setDisableBtn(true);
      try {
        setUploadImageModal(false);
        for (let i = 0; i < files.length; i++) {
          //getPresignedUrl
          const { presignedUrl, imgKey } = await getPresignedURL({
            contentType: files[i]?.type,
            key: `/journal/:${journalId}/page/:${imgFileNameConvention(
              files[i]?.name
            )}`,
          });

          //Upload Image On S3 Bucket
          axios({
            method: 'PUT',
            data: files[i],
            headers: {
              'Content-Type': files[i]?.type,
            },
            url: presignedUrl,
            // eslint-disable-next-line no-loop-func
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let precentage = Math.floor((loaded * 100) / total);
              if (precentage === 100) {
                fileCount++;
              }
              createProgressBar(
                files[i]?.name,
                `${formatBytes(total)}`,
                precentage,
                fileCount,
                files?.length
              );
            },
          }).then((res) => {
            //Add Page Call
            const data = {
              image: imgKey,
              journal: journalId,
              pageNum: i + 1 + (pagesCount || 0),
            };
            addPage(data)
              .then((res) => {})
              .catch((err) => {
                let currentFileprogress = document.getElementById(
                  `${journalId}${files[i]?.name}1`
                );
                let progressContainer = document.getElementById(
                  `${journalId}${files[i]?.name}`
                );
                let fileSize = document.getElementById(
                  `${journalId}${files[i]?.name}2`
                );
                progressContainer.children[1].children[0].children[0].style.color =
                  'red';
                fileSize.children[0].style.color = 'red';
                currentFileprogress.style.background = 'red';
                currentFileprogress.style.border = '1px solid red';
              });
          });
        }
      } catch (err) {
        console.log(err);
        //Upload Fail
        // let fileUpload = document.getElementById("file-count");
        // fileUpload.innerHTML = "Failed";
        // setPagesCount(pagesCount);
      } finally {
        setUploadImageModal(false);
        setDisableBtn(false);
        setPagesUploaded(false);
        setFiles('');
        setPagesCount(pagesCount + files?.length);
      }
    } else {
      setUploadImageModal(false);
      setOpenSnackbar(true);
      setMessageObject({ severity: 'info', text: t('Create Your Journal') });
      setPagesUploaded(true);
      setPagesCount(0);
    }
  };

  const originalFieldEmptyCheck = (data) => {
    let res = data;
    if (res?.fileTitle?.trim() === '') {
      res.fileTitle_sw = '';
    }
    if (res?.contributor?.trim() === '') {
      res.contributor_sw = '';
    }
    if (res?.description_en?.trim() === '') {
      res.description_sw = '';
    }
    if (res?.details?.trim() === '') {
      res.details_sw = '';
    }
    return res;
  };

  // Translate Button Func
  const handleTranslateData = async () => {
    try {
      setErrorDisplay(false);
      if (!fileData) return;
      // const data = { ...fileData };
      let category;
      if (fileData.category)
        category = categoriesData.find((ele) => ele._id === fileData?.category);
      console.log(journalFieldsEN);
      const newData = {
        ...journalFieldsToBeTranslated(
          fileData,
          journalFieldsEN || singleJournalDetails,
          locale
        ),
        tags: tagsToBeTranslated(tagsOptions, locale),
      };
      // const newData = {
      //   contributor_sw: fileData.contributor,
      //   category_sw: category,
      //   description_sw: fileData.description,
      //   details_sw: fileData.details,
      //   year_sw: fileData.year,
      //   fileTitle_sw: fileData.fileTitle,
      //   tags: tagsToBeTranslated(tagsOptions, locale),
      // };
      setJournalFieldsEN(newData);
      console.log('Before Translation', newData);

      let translatedData;
      if (locale === 'en') {
        translatedData = await translateData(newData);
      } else {
        translatedData = await translateDataSWToEN(newData);
      }
      console.log('After Translation', translatedData);
      setTagsOptons(translatedTags(tagsOptions, translatedData?.tags, locale));

      translatedData = translatedJournalFormat(
        translatedData,
        fileData,
        locale
      );

      translatedData = {
        ...fileData,
        ...translatedData,
        category_sw: locale === 'en' ? category?.name_sw : category?.name_en,
      };

      translatedData = originalFieldEmptyCheck(translatedData);

      setJournalFieldsEN(translatedData);

      // delete translatedData.tag_en;
      // delete translatedData.tags;
      // // translatedData.category_sw = translatedData.category_sw;
      // console.log('FILE DATA =>', data);
      setFileData(translatedData);
      // console.log('FILE DATA', translatedData);
      setSwFields(true);
    } catch (err) {
      console.log(err);
    } finally {
      setTranslateBtnLoading(false);
    }
  };

  // Save Details / Add Journal Func
  const handleAddJournal = async () => {
    try {
      setLoading(true);
      setDetectChangeTags(tagsOptions);
      setDetectTagsField(false);
      const {
        //fileTitle,
        description: description_en,
        details: subtitle_en,
        contributor: contributor_en,
        fileTitle_sw: title_sw,
        description_sw,
        details_sw: subtitle_sw,
        contributor_sw,
        category,
        year,
        // tag,
        tag_sw,
      } = fileData;

      let tagsFormat = tagsOptions.map((tags) => {
        if ('_id' in tags) {
          return { _id: tags?._id, en: tags.name_en, sw: tags.name_sw };
        } else {
          return { en: tags.name_en || '', sw: tags.name_sw || '' };
        }
      });
      console.log(tagsFormat);

      let data;
      if (locale === 'en') {
        data = {
          title_en: fileData?.fileTitle,
          description_en,
          subtitle_en,
          contributor_en,
          title_sw,
          description_sw,
          subtitle_sw,
          contributor_sw,
          category,
          year,
          tag: tagsFormat,
          tag_sw,
          image: fileCover,
          creator: userId,
        };
      } else {
        data = {
          title_en: fileData?.fileTitle_sw,
          description_en: description_sw,
          subtitle_en: subtitle_sw,
          contributor_en: contributor_sw,
          title_sw: fileData?.fileTitle,
          description_sw: fileData?.details,
          subtitle_sw: fileData?.subtitle,
          contributor_sw: fileData?.contributor,
          category,
          year,
          tag: tagsFormat,
          tag_sw,
          image: fileCover,
          creator: userId,
        };
      }

      console.log(fileData);

      // console.log('DATA', data, fileData);
      data.tags = tagsFormat;
      delete data.tag;
      delete data.tag_sw;
      console.log('DATA -> ', data);

      // Object.keys(data).map(
      //   (val) =>
      //     (data[val] =
      //       typeof data[val] == 'string' ? data[val].trim() : data[val])
      // );
      console.log(data);
      console.log(journalId, locale);
      try {
        const res = await updateJournal(journalId, data, locale);
        setMessageObject({
          text: 'Journal Updated.',
          severity: 'success',
        });
        setOpenSnackbar(true);
        console.log(res?.data);
        dispatch(setJournalDetails(res?.data));
      } catch (err) {
        console.log(err);
        dispatch(getAllTags);
        setMessageObject({
          text: err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.data,
          severity: 'error',
        });
        setOpenSnackbar(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleFileUpload = async (event) => {
    event?.preventDefault();

    let files;
    let fileSize = 0;
    let fileCount = 0;
    let index = 0;
    if (event?.type === 'change') {
      files = event?.target?.files;
    } else if (event?.type === 'drop') {
      files = event?.dataTransfer?.files;
    }
    // eslint-disable-next-line array-callback-return
    let allowedFiles = Object.entries(files)
      // eslint-disable-next-line array-callback-return
      ?.filter((file) => {
        if (validateFileUpload(['jpg', 'jpeg', 'png', 'gif'], file[1]?.name)) {
          fileCount++;
          return file;
        }
      })
      .reduce((accum, [, v]) => {
        console.log(index, v);
        accum[index++] = v;
        return accum;
      }, {});
    allowedFiles.length = fileCount;

    //Total Size Of Files
    for (let i = 0; i < allowedFiles.length; i++) {
      fileSize += allowedFiles[i]?.size;
    }

    if (fileCount >= 1) {
      setFileSize(formatBytes(fileSize));
      setFiles(allowedFiles);
      setTotalFileCount(toalFileCount + allowedFiles?.length);
    } else {
      setOpenSnackbar(true);
      setMessageObject({ severity: 'info', text: t('File Not Supported') });
    }
  };

  const getJournalPages = async () => {
    try {
      const res = await getPages(journalId, dispatch);
      console.log(res);
      // dispatch(setJournalDetails(res?.pages[0]?.journal));
    } catch (err) {
      // console.log(err);
    }
  };

  const handleDiscardChanges = () => {
    handleNavigation(navigationLink);
    console.log('Discard Changes', navigationLink);
    // customNavigate(navigationLink);
  };

  const handleSaveChanges = async () => {
    setSaveChangesLoader(true);
    await handleAddJournal();
    setSaveChangesLoader(false);
    handleNavigation(navigationLink);
    // customNavigate(navigationLink, { state: { page: location.state } });
  };
  useEffect(() => {
    if (journalId) {
      getJournalPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);

  useEffect(() => {
    if (pages) {
      setPagesCount(pages?.totalCount);
    }
  }, [pages]);

  //Remove Pages On Unmount
  useEffect(() => {
    return () => {
      setPagesCount(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper>
      {fileData?.category ? (
        <>
          {' '}
          {uploadImageModal ? (
            <Modal
              title={t('Upload and attach file')}
              open={uploadImageModal}
              handleClose={() => setUploadImageModal(false)}
              style={
                files
                  ? { ...uploadImgModalStyles, minHeight: '209px' }
                  : { ...uploadImgModalStyles }
              }
              children={
                <>
                  {!files ? (
                    <DragDrop handleFileUpload={handleFileUpload} />
                  ) : (
                    <UploadedFilesWrapper>
                      <div id='Selected-files-details'>
                        <img
                          src={GalleryImport}
                          alt=''
                          width='32px'
                          height='32px'
                        />
                        <div>
                          <p className='files-count'>
                            {files?.length} Pages selected
                          </p>
                          <p className='files-size'>{fileSize}</p>
                        </div>
                      </div>
                      <div id='fileDel-icon' onClick={() => setFiles(null)}>
                        <img alt='' src={CloseIcon} />
                      </div>
                    </UploadedFilesWrapper>
                  )}
                  <UploadModalBtnWrapper>
                    <StyledButton
                      secondary='true'
                      onClick={() => setUploadImageModal(false)}
                    >
                      {t(`Cancel`)}
                    </StyledButton>
                    <StyledButton
                      secondary
                      disable={disableBtn || !files}
                      onClick={() => uploadImages(files, journalId)}
                    >
                      {t(`Upload`)}
                    </StyledButton>
                  </UploadModalBtnWrapper>
                </>
              }
            />
          ) : null}{' '}
          <TopBar
            fileData={fileData}
            journalId={journalId}
            setJournalId={setJournalId}
            setAllowSwFields={setAllowSwFields}
            disabledButton={disabledButton}
            handleNavigation={() => {
              // handleNavigation('/files');
              setNavigationLink('/files');
              customNavigate(`/files`, { state: { page: location.state } });
            }}
            handleTranslateData={handleTranslateData}
            handleAddJournal={handleAddJournal}
            setLoading={setLoading}
            setTranslateBtnLoading={setTranslateBtnLoading}
            translateBtnLoading={translateBtnLoading}
            loading={loading}
            // setNewFileComponent={setNewFileComponent}
            tagsSW={tagsSW}
            tagsEN={tagsEN}
            swFields={swFields}
            setRemoveJournalComponent={setRemoveJournalComponent}
          />
          <StyledGrid container>
            <Grid
              item
              xs={6}
              sx={{ p: 2, height: 'calc(100vh - 125px)', overflow: 'auto' }}
            >
              <AddJournal
                setFileData={setFileData}
                fileData={fileData}
                swFields={swFields}
                allTags={allLanguagesTags}
                setErrorDisplay={setErrorDisplay}
                errorDisplay={errorDisplay}
                tagsEN={tagsEN}
                tagsSW={tagsSW}
                setTagsEN={setTagsEN}
                setTagsSW={setTagsSW}
                selectedTagsEN={selectedTagsEN}
                setSelectedTagsEN={setSelectedTagsEN}
                setSelectedTagsSW={setSelectedTagsSW}
                selectedTagsSW={selectedTagsSW}
                tagsOptions={tagsOptions}
                setTagsOptons={setTagsOptons}
                journalFieldsEN={journalFieldsEN}
                setJournalFieldsEN={setJournalFieldsEN}
                setDetectTagsField={setDetectTagsField}
                language={locale}
              />
            </Grid>
            <Grid item xs={6}>
              <RightSideWrapper>
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  xs={12}
                  sx={{ p: 2 }}
                >
                  <Typography
                    align='left'
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      lineHeight: '24px',
                      fontFamily: 'Open Sans',
                      color: '#292D32',
                    }}
                  >
                    {t(`Pages`)}
                  </Typography>
                  <Typography
                    align='left'
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '24px',
                      color: ' #7B858F',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    {t(`Total Pages`)} <PagesCount>{pagesCount}</PagesCount>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ height: '477px', background: '#F2F2F2' }}
                >
                  <UploadJournalCoverForm
                    setFileCover={setFileCover}
                    open={open}
                    setOpen={setOpen}
                    fileCover={fileCover}
                    fileData={fileData}
                    setMessageObject={setMessageObject}
                    setOpenSnackbar={setOpenSnackbar}
                  />

                  {!fileData?.image && (
                    <div
                      style={{ height: '100%', width: '100%' }}
                      // onClick={() => handleNavigation(`/files/${journalId}`)}
                    >
                      <CoverIcon
                        style={{
                          display: fileCover ? 'none' : 'flex',
                          margin: 'auto',
                          height: '100%',
                        }}
                      />
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ p: 1 }}
                  container
                  justifyContent='space-between'
                >
                  <CheckPermissions
                    requiredPermission={
                      journalId
                        ? ['634d3737298b7ce5da21b6a4']
                        : ['634d371c298b7ce5da21b6a1']
                    }
                  >
                    <StyledButton
                      secondary='true'
                      style={buttonStyles}
                      // disable={!journalId && true}
                      onClick={() => setUploadImageModal(true)}
                    >
                      {t(`Upload Page`)}
                    </StyledButton>
                  </CheckPermissions>
                  <StyledButton
                    style={buttonStyles}
                    disable={!journalId}
                    onClick={() => {
                      setNavigationLink(`/files/${journalId}`);
                      customNavigate(`/files/${journalId}`, {
                        state: { page: location.state },
                      });
                    }}
                    secondary='true'
                    // disable={!journalId && true}
                  >
                    {t(`View All Pages`)}
                  </StyledButton>
                </Grid>
              </RightSideWrapper>
            </Grid>
          </StyledGrid>
          {openSnackbar ? (
            <StyledSnackbar
              handleClose={handleSnackbar}
              open={openSnackbar}
              messageObject={messageObject}
            />
          ) : null}
          {removeJournalComponent && (
            <Modal
              title='Remove Journal'
              open={removeJournalComponent}
              handleClose={() => setRemoveJournalComponent(false)}
            >
              <RemoveJournal
                journalId={journalId}
                journals={journals}
                fileTitle={fileTitle}
                // removeFromJournalsList={removeFromJournalsList}
                // setNewFileComponent={setNewFileComponent}
                handleClose={() => setRemoveJournalComponent(false)}
                setMessageObject={setMessageObject}
                setOpenSnackbar={setOpenSnackbar}
              />
            </Modal>
          )}
        </>
      ) : (
        <Loader />
      )}
      {showWarning && (
        <Modal
          title='Unsaved Changes'
          open={showWarning}
          handleClose={() => setShowWarning(false)}
        >
          <UnsavedChangesWarning
            handleDiscardChanges={handleDiscardChanges}
            handleSaveChanges={handleSaveChanges}
            loading={saveChangesLoader}
          />
        </Modal>
      )}
    </AppWrapper>
  );
}

export default EditJournalContainer;
