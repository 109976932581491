export const getExistingSWTags = (allTags, translateResponse, selectedTags,language) => {
  //Language Switch Check
  if(language === "en"){
  let result = [];
  for (let i = 0; i < selectedTags.length; i++) {
    if (translateResponse[i] === '') {
      let existingTags = allTags?.find(
        (tag) => tag?._id === selectedTags[i]?._id
      );
      result.push(existingTags?.name_sw);
    } else {
      result.push(translateResponse[i]);
    }
  }
  return result;
}
else{
  let result = [];
  for (let i = 0; i < selectedTags.length; i++) {
    if (translateResponse[i] === '') {
      let existingTags = allTags?.find(
        (tag) => tag?._id === selectedTags[i]?._id
      );
      result.push(existingTags?.name_en);
    } else {
      result.push(translateResponse[i]);
    }
  }
  return result;
}
};

export const getSWTagsValue = (allTags, selectedTag, language) => {
  if(language === "en"){
  let existingTag = allTags.find((tag) => tag?._id === selectedTag?._id);
  return existingTag?.name_sw;
  }
  else{
    let existingTag = allTags.find((tag) => tag?._id === selectedTag?._id);
  return existingTag?.name_en;
  }
};

export const getTag = (tag, language) => {
  if(language){
  if (typeof tag === 'object') {
    return tag?.name || tag?.name_en;
  } else {
    return tag;
  }
}
else{
  if (typeof tag === 'object') {
    return tag?.name_sw || tag?.name;
  } else {
    return tag;
  }
}
};

//Param: tags is [{}]
export const getSpecificJournalTags=(tags,language)=>{
  let result=[];
  if(language === "en"){
  for(let key in tags){
    if(typeof tags[key] === "object"){
    result.push(tags[key]?.['name_en']?.toLowerCase());
    }
    else{
      result.push(tags[key])
    }
  }
}
else{
  for(let key in tags){
    if(typeof tags[key] === "object"){
    result.push(tags[key]?.['name_sw']?.toLowerCase());
    }
    else{
      result.push(tags[key])
    }
  }
}
  return result;
}

export const checkExistingTags=(tagsEN)=>{
  return tagsEN.map((ele) => {
    if (typeof ele !== "object") {
      return ele;
    } else {
      return ""; //for existing tag
    }
  })
}

export const checkExistingDescriptions = (desc,pages) =>{

  if(pages?.find((page)=> page?.description === desc)){
    return "existing";
  }
  else{
    return desc;
  }
}

export const tagsToBeTranslated=(tags, locale)=>{
  let res=[];
  if(locale === 'en'){
  // eslint-disable-next-line array-callback-return
   tags.forEach((tag)=>{
    if(!("_id" in tag)){
       res.push(tag?.name_en.toLowerCase());
    }
  })
  return res;
}
else{
 // eslint-disable-next-line array-callback-return
tags.forEach((tag)=>{
  if(!("_id" in tag)){
    res.push(tag?.name_sw.toLowerCase());
  }
})
return res;
}
}

export const translatedTags=(tags,translatedTags,locale)=>{
  console.log(tags,translatedTags,locale);
  let i=0;
  let res;
  if(locale === "en"){
  res=tags.map((tag)=>{
    if(!("_id" in tag)){
      return {...tag, name_sw: translatedTags[i++].toLowerCase() }
    }
    else{
      return tag;
    }
  })
  console.log(res);
  return res;
}
else{
  res=tags.map((tag)=>{
    if(!("_id" in tag)){
      return {...tag, name_en: translatedTags[i++].toLowerCase() }
    }
    else{
      return tag;
    }
  })
  return res;
}
}
function compareArrays(arr1, arr2) {
  console.log(arr1)
  console.log(arr2)
  if (arr1.length !== arr2.length) {
    return false; // arrays have different lengths
  }
  
  return arr1.every((obj1) =>
    arr2.some((obj2) => isEqual(obj1, obj2))
  );
}

function isEqual(obj1, obj2) {
  // implement your comparison logic here
  // for example, check if all properties are equal:
  return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
}

export const changeDeteckTags=(updatedTags,savedTags)=>{
   const res=compareArrays(updatedTags,savedTags);
   console.log(res);
   return res;
}